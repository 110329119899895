/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const React = require("react")
const PubSub = require("pubsub-js")
// import PubSub from "pubsub-js"
// You can delete this file if you're not using it
exports.onRouteUpdate = ({ location, prevLocation }) => {
  console.log("new pathname", location.pathname)
  console.log("old pathname", prevLocation ? prevLocation.pathname : null)
  PubSub.publish("onRouteUpdate", location)
}
